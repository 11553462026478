import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser, setUserRegisteredAlert } from '../../../redux/actions/Auth';
import { fetchLeadsSuccess } from '../../../redux/actions/LeadsActions';

import React from 'react';
import axios from './config';
import { rootUrl } from '../../../api-handling/rootUrl';
import { updateBusinessProfileWithoutReload } from '../../../redux/actions/BusinessProfileActions';
import sweetAlerts, { sweetAlertsOkBtn } from '../../../appmakers/components/SweetAlertHelper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToSeoUrl } from '../../../routes/Pages/Common/commonFunctions'
import { set } from 'lodash';
import { history } from '../../../redux/store';

const MySwal = withReactContent(Swal);


const JWTAuth = {
  onRegisterNormalUser: ({ name, email, password, userType, history }, resetStateHandler, setLoading) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/register/user', {
          email: email,
          password: password,
          userName: name,
          userType: userType,
          status: 'PENDING',
        })
        .then(({ data }) => {
          setLoading(false)
          dispatch(fetchSuccess());

          if (data.error) {


            if (data.error == "Deleted User Detected") {
              // sweetAlerts("Deleted User Detected", 'error', 'Failed');

              MySwal.fire({
                title: 'Deactivated User Detected',
                text: "Do you want to activate user?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, activate it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
              }).then(result => {
                if (result.value) {
                  //console.log("result")
                  // request(dispatch);
                  fetch(`${rootUrl}/activate-account`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "email": email })
                  }).then((res) => res.json()).then(() => {
                    MySwal.fire('Success', 'Your account has been reactivated :)', 'success');
                    setTimeout(() => {
                      window.location.replace('/home');
                      //window.history.pushState('', '', '/home');
                    }, 3000);
                  })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  MySwal.fire('Cancelled', 'Your account is still deactivated ):', 'success');
                }
              });
              // dispatch(fetchError(data.error));
            } else {
              sweetAlerts(data.error, 'error');
            }

          } else if (data.success) {
            resetStateHandler();
            sweetAlertsOkBtn('You have been sent a verification email. Please check you email', 'success', 'Registered', history);
          }
        })
        .catch(function (error) {
          sweetAlerts(error, 'error');
          setLoading(false)
          dispatch(fetchSuccess());
        });
    };
  },

  onRegisterBusinessUser: (
    { name, email, password, phone, businessName, businessUrl, profession, businessNumber, address, userType, history, createBusiness },
    resetStateHandler,
  ) => {
    //debugger;
    return dispatch => {

      axios
        .post('/register/user', {
          email,
          password,
          userName: name,
          mobile: phone,
          businessName,
          businessUrl,
          businessProfession: profession,
          businessABN: businessNumber,
          location: address,
          userType: userType,
          status: 'PENDING',
          createBusiness
        })
        .then(({ data }) => {
          if (data.error) {
            dispatch(fetchSuccess());
            if (data.error == "Deleted User Detected") {
              // sweetAlerts("Deleted User Detected", 'error', 'Failed');

              MySwal.fire({
                title: 'Deactivated User Detected',
                text: "Do you want to activate user?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, activate it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
              }).then(result => {
                if (result.value) {
                  // console.log("result")
                  // request(dispatch);
                  fetch(`${rootUrl}/activate-account`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "email": email })
                  }).then((res) => res.json()).then(() => {
                    MySwal.fire('Success', 'Your account has been reactivated :)', 'success');
                    setTimeout(() => {
                      window.location.replace('/home');
                      //window.history.pushState('', '', '/home');
                    }, 3000);
                  })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  MySwal.fire('Cancelled', 'Your account is still deactivated ):', 'success');
                }
              });
              // dispatch(fetchError(data.error));
            } else {
              sweetAlerts(data.error, 'error');
            }
          } else if (data.success) {
            resetStateHandler();
            dispatch(fetchSuccess());
            sweetAlertsOkBtn('You have been sent a verification email. Please check you email', 'success', 'Registered', history, createBusiness);
          }
        })
        .catch(function (error) {
          dispatch(fetchSuccess());
          sweetAlerts(error, 'error');
        });
    };
  },

  onLogin: ({ email, password, checkRememberme }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('login-user', {
            email: email,
            password: password,
            isApk: false
          })
          .then(({ data }) => {
            if (data.token && data.user && data.user._id) {
              localStorage.removeItem('token');
              sessionStorage.removeItem("token");
              localStorage.removeItem('user');
              sessionStorage.removeItem("user");
              dispatch(setAuthUser(null));
              if (checkRememberme) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
              } else {
                sessionStorage.setItem("token", data.token);
                sessionStorage.setItem('user', JSON.stringify(data.user));
              }
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
              if (data.user.userType === 'Customer User') {
                //window.location.replace('/my-jobs');
                dispatch(JWTAuth.getAuthUser(true, data.token, true, '/my-jobs'));
                // setTimeout(() => {
                //   history.push('/my-jobs');
                // }, 3000)
              } else if (data.user.userType === 'Business User') {
                if (data.user.switchedToCustomerView) {
                  //window.location.replace('/my-jobs');
                  dispatch(JWTAuth.getAuthUser(true, data.token, true, '/my-jobs'));
                  // setTimeout(() => {
                  //   history.push('/my-jobs');
                  // }, 3000)
                } else {
                  dispatch(JWTAuth.getAuthUser(true, data.token, true, '/leads/tab'));

                  //window.location.replace('/leads/tab');
                }
              }
            } else if (data.message === "Deleted User Detected") {
              // sweetAlerts("Deleted User Detected", 'error', 'Failed');

              MySwal.fire({
                title: 'Deactivated User Detected',
                text: "Do you want to activate user?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, activate it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
              }).then(result => {
                if (result.value) {
                  // console.log("result")
                  // request(dispatch);
                  fetch(`${rootUrl}/activate-account`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "email": email })
                  }).then((res) => res.json()).then(() => {
                    MySwal.fire('Success', 'Your account has been reactivated :)', 'success');
                  })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  MySwal.fire('Cancelled', 'Your account is still deactivated ):', 'success');
                }
              });
              // dispatch(fetchError(data.error));
            } else if (data.message == 'Spam user detected') {
              sweetAlerts("Something went wrong. Please contact to admin.", 'error', 'Failed');
            } else {
              // console.log("deactivated")
              sweetAlerts("Invalid Credentials", 'error', 'Failed');
            }
            dispatch(fetchSuccess());
          })
          .catch(function (error) {
            sweetAlerts('Something went wrong', 'error');
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        sweetAlerts('Something went wrong', 'error');
        dispatch(fetchError(error.message));
      }
    };
  },
  onSocialMediaLogin: ({ email, token, message, checkRememberme = true }) => {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      try {
        dispatch(fetchStart());
        axios
          .post('auth/me')
          .then(({ data }) => {
            if (token && data.user && data.user._id) {
              localStorage.removeItem('token');
              sessionStorage.removeItem("token");
              localStorage.removeItem('user');
              sessionStorage.removeItem("user");
              dispatch(setAuthUser(null));
              if (checkRememberme) {
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(data.user));
              } else {
                sessionStorage.setItem("token", token);
                sessionStorage.setItem('user', JSON.stringify(data.user));
              }
              dispatch(JWTAuth.getAuthUser(true, token));
              if (data.user.userType === 'Customer User') {
                window.location.replace('/my-jobs');
              } else if (data.user.userType === 'Business User') {
                if (data.user.switchedToCustomerView) {
                  window.location.replace('/my-jobs');
                } else {
                  window.location.replace('/leads/tab');
                }
              }
            } else if (message === "Deleted User Detected") {
              // sweetAlerts("Deleted User Detected", 'error', 'Failed');

              MySwal.fire({
                title: 'Deactivated User Detected',
                text: "Do you want to activate user?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, activate it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
              }).then(result => {
                if (result.value) {
                  // console.log("result")
                  // request(dispatch);
                  fetch(`${rootUrl}/activate-account`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ "email": email })
                  }).then((res) => res.json()).then(() => {
                    MySwal.fire('Success', 'Your account has been reactivated :)', 'success');
                  })
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  MySwal.fire('Cancelled', 'Your account is still deactivated ):', 'success');
                }
              });
              // dispatch(fetchError(data.error));
            } else if (message == 'Spam user detected') {
              sweetAlerts("Something went wrong. Please contact to admin.", 'error', 'Failed');
            } else {
              //console.log("deactivated")
              sweetAlerts("Invalid Credentials", 'error', 'Failed');
            }
            dispatch(fetchSuccess());
          })
          .catch(function (error) {
            sweetAlerts('Something went wrong', 'error');
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        sweetAlerts('Something went wrong', 'error');
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/logout')
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            dispatch(fetchLeadsSuccess({ data: [], moreToLoad: true }));
            localStorage.removeItem('token');
            sessionStorage.removeItem("token");
            localStorage.removeItem('user');
            sessionStorage.removeItem("user");
            dispatch(setAuthUser(null));
            window.location.href = '/home';
            //history.push('/home');
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          console.log('error', error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token, redirect = false, url = "") => {
    return dispatch => {
      if (!token) {
        let token;
        token = localStorage.getItem('token');
        if (!token)
          token = sessionStorage.getItem('token');

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      const allowUrl = ['/business-details', '/iframehome', '/signin', '/login', '/socialsignin', '/signup',
        '/set-password', '/reset-password', '/signup-business', '/business-directory', '/forgot-password',
        '/profession', '/category', '/ads', '/business-details', '/jobiframe', '/businessiframe', '/help', '/policy',
        '/about', '/how-it-works'];
      function isInclude(reqUrl) {
        return allowUrl.some(url => reqUrl.includes(url));
      }

      axios
        .post('auth/me', { isApk: false })
        .then(({ data }) => {
          if (data.user && data.user._id) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser({ ...data.user, card: data.card }));
            if (redirect) {
              history.push(url);
            }
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status === 401 && !isInclude(window.location.pathname)) {
            let token = localStorage.removeItem('token');
            if (!token)
              token = sessionStorage.removeItem("token");

            if (token) {
              Swal.fire({
                title: "Login session is expired !",
                text: "Your login session is expired. Please login again.",
                icon: "warning",
                // confirmButtonColor: "#3085d6",
                // confirmButtonText: "Ok"
              })
            }
            // .then((result) => {
            //   if (result.isConfirmed) {
            //     Swal.fire({
            //       title: "Deleted!",
            //       text: "Your file has been deleted.",
            //       icon: "success"
            //     });
            //   }
            //});

            localStorage.removeItem('token');
            sessionStorage.removeItem("token");
            localStorage.removeItem('user');
            sessionStorage.removeItem("user");
            dispatch(setAuthUser(null));
            history.push('/home');
          }
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: (isBusinessUser = false) => {
    var googleAuthUrl = rootUrl + '/auth/google';
    var linkedinAuthUrl = rootUrl + '/auth/linkedin';
    var facebookAuthUrl = rootUrl + '/auth/facebook';

    if (isBusinessUser) {
      googleAuthUrl = rootUrl + '/auth/google/businessUser';
    }
    if (isBusinessUser) {
      linkedinAuthUrl = rootUrl + '/auth/linkedin/businessUser';
    }

    if (isBusinessUser) {
      facebookAuthUrl = rootUrl + '/auth/facebook/businessUser';
    }
    return (
      <React.Fragment>
        <br />
        <p className="text-center text-gray-500 font-bold">Sign in using:</p>
        <div className="d-flex flex-row justify-content-between align-items-center w-50 m-auto">
          <a href={googleAuthUrl} className="cursor-pointer">
            <img src="/images/icons/googleplus.png" alt="Google image" width="40px" />
          </a>
          <div className="cursor-pointer">
            <a href={linkedinAuthUrl} className="cursor-pointer">
              <img src="/images/icons/linkedin.jpg" alt="linkedin image" width="43px" />
            </a>
          </div>
          <div className="cursor-pointer">
            <a href={facebookAuthUrl} className="cursor-pointer">
              <img src="/images/icons/facebook.png" alt="facebook image" width="44px" />
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  },
};

export default JWTAuth;
