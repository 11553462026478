import { number } from "@storybook/addon-knobs";
import { useState, useEffect } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");
const SECRET_KEY = '#Tradingseek#123#Tradingseek#';
const encrypt = ((text) => {
  var ciphertext = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
  return ciphertext;
});
function decrypt(text) {
  var bytes = CryptoJS.AES.decrypt(text, SECRET_KEY);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
let Emailcheck = (inputText) => {
  if (typeof inputText != "string") {
    return inputText;
  }
  var replacePattern = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  let arr = inputText?.split(' ');
  let newArr = arr?.map((item) => {
    if (item.match(replacePattern)) {
      return `${item?.split('@')[0]?.slice(0, -(item?.split('@')[0].length - 4)) + "xxxxx" + '@' + "xxxxx" + item?.split('@')[1]?.slice(item?.split('@')[1].length - 4)}`
    } else {
      return item
    }
  })
  return newArr?.join(' ');
};
const ToSeoUrl = (url) => {
  //debugger
  // make the url lowercase         
  var encodedUrl = url?.toString().toLowerCase();
  // replace & with and           
  encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
  // remove invalid characters 
  encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
  // remove duplicates 
  encodedUrl = encodedUrl?.split(/-+/).join("-");
  // trim leading & trailing characters 
  encodedUrl = encodedUrl?.trim('-');
  return encodedUrl;
}
const formatNumberAfterSubmit = (number) => {
  //debugger
  number = number.toString();
  let newNumArr = number.split(".");
  if (newNumArr.length > 1) {
    if (newNumArr[1].length == 0) {
      let newNum = `${newNumArr[0]}`;
      return newNum
    } else if (newNumArr[1].length == 1) {
      let desimal = newNumArr[1];
      let newdesimal = `${desimal}0`;
      let newNum = `${newNumArr[0]}.${newdesimal}`;
      return newNum
    } else {
      return number;
    }
  } else {
    return number;
  }
}
const formatNumber = (number) => {
  number = number.toString();
  let newNumArr = number.split(".");
  if (newNumArr.length > 1) {
    if (newNumArr[1].length >= 2 || newNumArr.length > 2) {
      let desimal = newNumArr[1].toString();
      let newdesimal = desimal.substring(0, 2);
      let newNum = `${newNumArr[0]}.${newdesimal}`;
      return newNum
    } else {
      return number;
    }
  } else {
    return number;
  }
}

const validateMobileWithCode = (phone, cCode) => {
  let phRejex;
  if (cCode == '+91') {
    phRejex = /^[6-9]\d{9}$/
  } else if (cCode == '+61') {
    //phRejex = /^[4]\d{8}$/
    phRejex = /^[1-9]\d{7,9}$/
  } else if (cCode == '+64') {
    phRejex = /^[2-9]\d{7,9}$/
  } else {
    phRejex = /^[0-9]/
  }
  if (phone.match(phRejex))
    return true;
  else
    return false;
}
function getWindowDimensions() {
  const width = window.innerWidth
  const height = window.innerHeight
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function isValidGSTIN(gstin) {
  const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

  if (!gstinRegex.test(gstin)) {
    return false; // GSTIN does not match the expected format
  }
  else {
    return true
  }
}

function validateBusiness(countryCode, businessNumber) {
  return new Promise((resolve, reject) => {
    if (countryCode == 'NZ') {
      // const requestOptions = {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: 'Bearer dd41bb5137cf5fe45c62d6e7e6692d6b',
      //   },
      // };
      fetch(`https://api.business.govt.nz/gateway/nzbn/v5/entities?search-term=${businessNumber}`, {
        method: 'GET',
        // Request headers
        headers: {
          'Cache-Control': 'no-cache',
          'Ocp-Apim-Subscription-Key': '983b623ff01c4f1981e25f57a8a4ac47',
        }
      })
        .then(res => res.json())
        .then(res => {
          let data;
          if (res.items?.length > 0) {
            data = res.items[0];
          } else {
            resolve({ err: true, message: 'Business Number is not valid' });
          }
          if (data.errorDescription) {
            resolve({ err: true, message: 'Business Number is not valid' });
          }
          if (data.entityStatusDescription === 'Registered') {
            resolve({ err: false, message: 'Business Number is Registered' });
          } else {
            resolve({ err: true, message: 'NZBN status shows Removed / Inactive' });
          }
        }).catch((err) => {
          reject(err)
        })

      // fetch(`https://api.business.govt.nz/services/v4/nzbn/entities/${businessNumber}`, requestOptions)
      //   .then(res => res.json())
      //   .then(data => {
      //     if (data.errorDescription) {
      //       resolve({ err: true, message: 'Business Number is not valid' });
      //     }
      //     if (data.entityStatusDescription === 'Registered') {
      //       resolve({ err: false, message: 'Business Number is Registered' });
      //     } else {
      //       resolve({ err: true, message: 'NZBN status shows Removed / Inactive' });
      //     }
      //   }).catch((err) => {
      //     reject(err)
      //   })

    } else if (countryCode == 'AU') {

      const requestOptions = {
        method: 'GET',
      };
      const GUID = 'c71ebea0-b755-41e9-97d4-57fadfedf642'
      fetch(`https://abr.business.gov.au/json/AbnDetails.aspx?abn=${businessNumber}&guid=${GUID}`, requestOptions)
        .then(res => res.text())
        .then(data => {
          if (data.includes("Active")) {
            resolve({ err: false, message: 'ABN / ACN Number is Active' });
          } else {
            fetch(`https://abr.business.gov.au/json/AcnDetails.aspx?acn=${businessNumber}&guid=${GUID}`, requestOptions)
              .then(res => res.text())
              .then(data => {
                if (data.includes("Active")) {
                  resolve({ err: false, message: 'ABN / ACN Number is Active' });
                } else {
                  resolve({ err: true, message: 'ABN / ACN Number is Not Active' })
                }
              }).catch((err) => {
                reject(err)
              })
          }
        }).catch((err) => {
          reject(err)
        })
    } else if (countryCode == 'IN') {
      const isValid = isValidGSTIN(businessNumber);
      if (isValid)
        resolve({ err: false, message: 'Business Number is Registered' });
      else
        resolve({ err: true, message: 'GSTIN Number is Not Valid' })
    }
  });
}

const getNoOfLater = (windowSize) => {
  // if (windowSize.width > 900 && currentUser)
  //   return 15;
  if (windowSize.width <= 350)
    return 23;
  else if (windowSize.width <= 400)
    return 30;
  else if (windowSize.width <= 450)
    return 35;
  else if (windowSize.width <= 500)
    return 40;
  else if (windowSize.width <= 550)
    return 45;
  else if (windowSize.width <= 600)
    return 50;
  else if (windowSize.width <= 650)
    return 55;
  else if (windowSize.width <= 700)
    return 70;
  else if (windowSize.width <= 766)
    return 75;
  else if (windowSize.width < 800)
    return 15;
  else if (windowSize.width < 960)
    return 15;
  else if (windowSize.width >= 960)
    return 23;
};
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700,
      700,
      "JPEG",
      60,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export {
  ToSeoUrl, encrypt,
  decrypt, Emailcheck,
  formatNumber, validateMobileWithCode,
  formatNumberAfterSubmit, useWindowDimensions,
  validateBusiness, getNoOfLater, resizeFile
}