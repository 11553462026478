import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { http } from '../appmakers/services/http-service';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { CircularProgress } from '@material-ui/core';
import { NotificationContainer } from 'react-notifications';
import { AuhMethods } from '../services/auth';
import { CurrentAuthMethod } from '../@jumbo/constants/AppConstants';

// import { Home } from './Pages/UnAuthenticated/Home';
// import Login from './Auth/Login';
// import { LoginForm } from './Pages/UnAuthenticated/Home/login';
// import Googleads from './Pages/Common/Googleads';
// import SocialSignIn from "../@jumbo/components/Common/authComponents/socialSignIn"
// import Error404 from './Pages/404';
// import Register from './Auth/Register';
// import RegisterBusiness from './Auth/RegisterBusiness';
// import CreatePassword from './Auth/SetPassword';
// import ForgotPasswordPage from './Auth/ForgotPassword';
// import BusinessDirectory from './Pages/UnAuthenticated/BusinessDirectory/BusinessDirectory';
// import MyJobs from './Pages/Authenticated/myJobs';
// import Leads from './Pages/Authenticated/leads';
// import LeadDetails from './Pages/Authenticated/leads/details/indexNew1';
// import Plans from './Pages/Authenticated/plans/index';
// import Chat from './Pages/Authenticated/Chat';
// import BusinessSettings from './Pages/Authenticated/leads/BusinessSettings';
// import normalUserSettings from './Pages/Authenticated/normalUser';
//import MyBusinessProfile from './Pages/Authenticated/businessUser/myProfile';
//import SpecificProfession from './Pages/UnAuthenticated/BusinessDirectory/SpecificProfession/SpecificProfession';
//import LocationList from './Pages/UnAuthenticated/BusinessDirectory/SpecificProfession/LocationList';
//import BusinessPublicProfile from './Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile/indexNew';
////import BusinessPublicProfile from './Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile';
//import JobIframe from './Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile/iframe';
//import BusinessIframe from './Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile/businessProfileIfeame';
// import Policy from './Pages/Common/Policy/Policy';
// import Help from './Pages/Common/Help';
// import CreateResetPassword from './Auth/ResetPassword';
// import About from './Pages/UnAuthenticated/About/About';
// import HowItWorks from './Pages/UnAuthenticated/HowItWorks/';

const Home = lazy(() => import('./Pages/UnAuthenticated/Home'));
const Login = lazy(() => import('./Auth/Login'));
const LoginForm = lazy(() => import('./Pages/UnAuthenticated/Home/login'));
const Googleads = lazy(() => import('./Pages/Common/Googleads'));
const SocialSignIn = lazy(() => import('../@jumbo/components/Common/authComponents/socialSignIn'));
const Error404 = lazy(() => import('./Pages/404'));
const Register = lazy(() => import('./Auth/Register'));
const RegisterBusiness = lazy(() => import('./Auth/RegisterBusiness'));
const CreatePassword = lazy(() => import('./Auth/SetPassword'));
const ForgotPasswordPage = lazy(() => import('./Auth/ForgotPassword'));
const BusinessDirectory = lazy(() => import('./Pages/UnAuthenticated/BusinessDirectory/BusinessDirectory'));
const MyJobs = lazy(() => import('./Pages/Authenticated/myJobs'));
const Leads = lazy(() => import('./Pages/Authenticated/leads'));
const LeadDetails = lazy(() => import('./Pages/Authenticated/leads/details/indexNew1'));
const Plans = lazy(() => import('./Pages/Authenticated/plans/index'));
const Chat = lazy(() => import('./Pages/Authenticated/Chat'));
const normalUserSettings = lazy(() => import('./Pages/Authenticated/normalUser'));
const BusinessSettings = lazy(() => import('./Pages/Authenticated/leads/BusinessSettings'));
const MyBusinessProfile = lazy(() => import('./Pages/Authenticated/businessUser/myProfile'));
const SpecificProfession = lazy(() => import('./Pages/UnAuthenticated/BusinessDirectory/SpecificProfession/SpecificProfession'));
const LocationList = lazy(() => import('./Pages/UnAuthenticated/BusinessDirectory/SpecificProfession/LocationList'));
const BusinessPublicProfile = lazy(() => import('./Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile/indexNew'));
const JobIframe = lazy(() => import('./Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile/iframe'));
const BusinessIframe = lazy(() => import('./Pages/UnAuthenticated/BusinessDirectory/BusinessPublicProfile/businessProfileIfeame'));
const Policy = lazy(() => import('./Pages/Common/Policy/Policy'));
const Help = lazy(() => import('./Pages/Common/Help'));
const CreateResetPassword = lazy(() => import('./Auth/ResetPassword'));
const About = lazy(() => import('./Pages/UnAuthenticated/About/About'));
const HowItWorks = lazy(() => import('./Pages/UnAuthenticated/HowItWorks/'));

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const RestrictedRouteBusiness = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser && authUser.userType == 'Business User' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const classes = useStyles();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={{ pathname: '/home', search: location.search }} />;
  } else if (
    authUser &&
    authUser.userType === 'Customer User' &&
    (location.pathname === '/signin' ||
      location.pathname === '/signup' ||
      location.pathname === '/forgot-password' ||
      location.pathname === '/leads/tab' ||
      location.pathname === '/leads/my-business-profile' ||
      location.pathname === '/lead-details/:id')
  ) {
    return <Redirect to={{ pathname: '/my-jobs', search: location.search }} />;
  }
  // else if (
  //     authUser && authUser.userType==='Business User' &&
  //     (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password'
  //     || location.pathname==='/my-jobs')
  // ) {
  //     return <Redirect to={'/leads'}/>;
  // }
  http.interceptors.response.use(
    response => response,
    error => {
      if (error?.data?.msg == 'User is deleted') {
        MySwal.fire('Deleted', 'Your account is deleted', 'success');
        setTimeout(() => {
          dispatch(AuhMethods[CurrentAuthMethod].onLogout());
        }, 2500)
      } else if (error?.data?.msg == 'Spam user') {
        MySwal.fire('Failed', 'Something went wrong. Please contact to admin.', 'error');
        setTimeout(() => {
          dispatch(AuhMethods[CurrentAuthMethod].onLogout());
        }, 2500)
      }
      return Promise.reject(error);
    }
  );
  return (
    <React.Fragment>
      <Suspense fallback={<Box className={classes.circularProgressRoot}>
        <CircularProgress />
      </Box>}>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/iframehome/:service?/:locations?" component={Home} />
          <Route path="/signin" component={Login} />
          <Route path="/login" component={LoginForm} />
          <Route path="/socialsignin" component={SocialSignIn} />
          <Route path="/signup" component={Register} />
          <Route path={`/set-password`} component={CreatePassword} />
          <Route path={`/reset-password`} component={CreateResetPassword} />
          <Route path="/signup-business" component={RegisterBusiness} />
          <Route path="/business-directory" component={BusinessDirectory} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/profession/:professionId/:selectedLocation?" component={SpecificProfession} />
          <Route path="/category/:professionName/:locationName" component={LocationList} />
          <Route path="/ads" component={Googleads} />
          <Route path="/business-details/:id" component={BusinessPublicProfile} />
          <Route path="/jobiframe/:service?/:locations?" component={JobIframe} />
          <Route path="/businessiframe/:service?/:locations?" component={BusinessIframe} />
          <Route path="/help" component={Help} />
          <Route path="/policy/:tid" component={Policy} />
          {/* <Route path="/policy">
          <Policy id={0} />
        </Route>
        <Route path="/terms">
          <Policy id={1} />
        </Route>
        <Route path="/faqs">
          <Policy id={2} />
        </Route> */}
          <Route path="/about" component={About} />
          <Route path="/how-it-works" component={HowItWorks} />

          <RestrictedRoute path="/my-jobs/:tabId?/:expandedId?" component={MyJobs} />
          <RestrictedRoute exact path="/leads/tab/:tabId?" component={Leads} />
          <RestrictedRoute path="/leads/settings" component={BusinessSettings} />
          <RestrictedRoute path="/plans/:jobId?" component={Plans} />
          <RestrictedRoute path="/settings" component={normalUserSettings} />
          <RestrictedRoute path="/leads/my-business-profile" component={MyBusinessProfile} />
          <RestrictedRouteBusiness path="/lead-details/:id/:userId?" component={LeadDetails} />
          <RestrictedRoute path="/inbox" component={Chat} />
          <RestrictedRoute path="/chat/:senderId/:receiverId/:jobId" component={Chat} />

          <Route component={Error404} />
        </Switch>
      </Suspense>
      <NotificationContainer />
    </React.Fragment>
  );
};

export default Routes;
