import nz from './Postcode.json';
import aus from './aus.json';
import ind from './ind.json';
import { symbol } from 'prop-types';
let locations;
let FrontendUrl;
let serverUrl;
let socketIoUrl;
let socketIoAutoQuoteUrl;
let Country;
let Country_dial_code;
let Country_code;
let Country_code_long;
let businessNumber_Placeholder;
let businessNumber_LookupMessage;
let businessNumber_LookupLink;

if (process.env.REACT_APP_COUNTRY === "AU") {
    locations = aus;
    FrontendUrl = 'https://tradingseek.com.au';
    serverUrl = 'https://api.tradingseek.com.au';
    socketIoUrl = 'https://api.tradingseek.com.au';
    socketIoAutoQuoteUrl = 'https://api.tradingseek.com.au/autoQuote';
    Country = 'Australia';
    Country_dial_code = "+61";
    Country_code = "AU";
    Country_code_long = "AUS";
    businessNumber_Placeholder = 'Business ABN / ACN';
    businessNumber_LookupMessage = 'Lookup my ABN / ACN';
    businessNumber_LookupLink = 'https://abr.business.gov.au/';
}
else if (process.env.REACT_APP_COUNTRY === "NZ") {
    locations = nz;
    FrontendUrl = 'https://tradingseek.co.nz';
    serverUrl = 'https://api.tradingseek.co.nz';
    socketIoUrl = 'https://api.tradingseek.co.nz';
    socketIoAutoQuoteUrl = 'https://api.tradingseek.co.nz/autoQuote';
    Country = 'New Zealand';
    Country_dial_code = "+64";
    Country_code = "NZ";
    Country_code_long = "NZL";
    businessNumber_Placeholder = 'Business NZBN';
    businessNumber_LookupMessage = 'Lookup my NZBN';
    businessNumber_LookupLink = 'https://companies-register.companiesoffice.govt.nz/';
}
else if (process.env.REACT_APP_COUNTRY === "IN") {
    locations = ind;
    FrontendUrl = 'https://tradingseek.in';
    serverUrl = 'https://api.tradingseek.in';
    socketIoUrl = 'https://api.tradingseek.in';
    socketIoAutoQuoteUrl = 'https://api.tradingseek.in/autoQuote';
    Country = 'India';
    Country_dial_code = "+91";
    Country_code = "IN";
    Country_code_long = "IND";
    businessNumber_Placeholder = 'Business GSTIN';
    businessNumber_LookupMessage = 'Lookup my GSTIN';
    businessNumber_LookupLink = 'https://services.gst.gov.in/services/searchtp';

}
else if (process.env.REACT_APP_COUNTRY === "NET") {
    locations = aus;
    FrontendUrl = 'https://tradingseek.net';
    serverUrl = 'https://api.tradingseek.net';
    socketIoUrl = 'https://api.tradingseek.net';
    socketIoAutoQuoteUrl = 'https://api.tradingseek.net/autoQuote';
    Country = 'Australia';
    Country_dial_code = "+61";
    Country_code = "AU";
    Country_code_long = "AUS";
    businessNumber_Placeholder = 'Business ABN / ACN';
    businessNumber_LookupMessage = 'Lookup my ABN / ACN';
    businessNumber_LookupLink = 'https://abr.business.gov.au/';
}
else {
    locations = aus;
    FrontendUrl = 'http://localhost:3000';
    serverUrl = 'http://localhost:8000';
    socketIoUrl = 'http://localhost:8000';
    socketIoAutoQuoteUrl = 'http://localhost:8000/autoQuote';
    Country = 'Australia';
    Country_dial_code = "+61";
    Country_code = "AU";
    Country_code_long = "AUS";
    businessNumber_Placeholder = 'Business ABN / ACN';
    businessNumber_LookupMessage = 'Lookup my ABN / ACN';
    businessNumber_LookupLink = 'https://abr.business.gov.au/';
}

const getPlans = () => {
    let plan;
    if (process.env.REACT_APP_COUNTRY == "AU") {
        plan = [
            { credits: 50, bonus: 10, price: 20, expDays: 90 },
            { credits: 100, bonus: 24, price: 50, expDays: 90 },
            { credits: 200, bonus: 50, price: 100, expDays: 90 },
            { credits: 500, bonus: 100, price: 200, expDays: 90 }
        ]
    } else if (process.env.REACT_APP_COUNTRY == "NZ") {
        plan = [
            { credits: 50, bonus: 10, price: 20, expDays: 90 },
            { credits: 100, bonus: 24, price: 50, expDays: 90 },
            { credits: 200, bonus: 50, price: 100, expDays: 90 },
            { credits: 500, bonus: 100, price: 200, expDays: 90 }
        ]
    } else if (process.env.REACT_APP_COUNTRY == "IN") {
        plan = [
            { credits: 50, bonus: 10, price: 20, expDays: 90 },
            { credits: 100, bonus: 24, price: 50, expDays: 90 },
            { credits: 200, bonus: 50, price: 100, expDays: 90 },
            { credits: 500, bonus: 100, price: 200, expDays: 90 }
        ]
    }
    if (process.env.REACT_APP_COUNTRY == "NET") {
        plan = [
            { credits: 50, bonus: 10, price: 20, expDays: 90 },
            { credits: 100, bonus: 24, price: 50, expDays: 90 },
            { credits: 200, bonus: 50, price: 100, expDays: 90 },
            { credits: 500, bonus: 100, price: 200, expDays: 90 }
        ]
    } else {
        plan = [
            { credits: 50, bonus: 10, price: 20, expDays: 90 },
            { credits: 100, bonus: 24, price: 50, expDays: 90 },
            { credits: 200, bonus: 50, price: 100, expDays: 90 },
            { credits: 500, bonus: 100, price: 200, expDays: 90 }
        ]
    }
    return plan;

}


const getMorePlanPriceAndBonusCredit = (credits) => {

}
const getMorePlans = (credits) => {
    let plan = {};
    let price, expDays, bonus;
    bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);

    if (process.env.REACT_APP_COUNTRY == "AU") {
        if (credits >= 500 && credits <= 1000) {
            price = credits * 0.40;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 1000 && credits <= 2000) {
            price = credits * 0.37;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 2000 && credits <= 3000) {
            price = credits * 0.35;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        else if (credits > 3000 && credits <= 4000) {
            price = credits * 0.33;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 4000) {
            price = credits * 0.31;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        plan = { credits: Math.round(credits), bonus: Math.round(bonus), price: Math.round(price), expDays }

    } else if (process.env.REACT_APP_COUNTRY == "NZ") {
        if (credits >= 500 && credits <= 1000) {
            price = credits * 0.40;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 1000 && credits <= 2000) {
            price = credits * 0.37;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 2000 && credits <= 3000) {
            price = credits * 0.35;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        else if (credits > 3000 && credits <= 4000) {
            price = credits * 0.33;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 4000) {
            price = credits * 0.31;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        plan = { credits: Math.round(credits), bonus: Math.round(bonus), price: Math.round(price), expDays }
    } else if (process.env.REACT_APP_COUNTRY == "IN") {
        if (credits >= 500 && credits <= 1000) {
            price = credits * 0.40;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 1000 && credits <= 2000) {
            price = credits * 0.37;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 2000 && credits <= 3000) {
            price = credits * 0.35;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        else if (credits > 3000 && credits <= 4000) {
            price = credits * 0.33;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 4000) {
            price = credits * 0.31;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        plan = { credits: Math.round(credits), bonus: Math.round(bonus), price: Math.round(price), expDays }
    } if (process.env.REACT_APP_COUNTRY == "NET") {
        if (credits >= 500 && credits <= 1000) {
            price = credits * 0.40;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 1000 && credits <= 2000) {
            price = credits * 0.37;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 2000 && credits <= 3000) {
            price = credits * 0.35;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        else if (credits > 3000 && credits <= 4000) {
            price = credits * 0.33;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 4000) {
            price = credits * 0.31;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        plan = { credits: Math.round(credits), bonus: Math.round(bonus), price: Math.round(price), expDays }

    } else {
        if (credits >= 500 && credits <= 1000) {
            price = credits * 0.40;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 1000 && credits <= 2000) {
            price = credits * 0.37;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 2000 && credits <= 3000) {
            price = credits * 0.35;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        else if (credits > 3000 && credits <= 4000) {
            price = credits * 0.33;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        } else if (credits > 4000) {
            price = credits * 0.31;
            bonus = ((credits / 1000) * credits) / ((credits / 1000) * 5);
            expDays = 90;
        }
        plan = { credits: Math.round(credits), bonus: Math.round(bonus), price: Math.round(price), expDays }
    }
    return plan;

}
const getMorePlansSettings = () => {
    let plan;
    if (process.env.REACT_APP_COUNTRY == "AU") {
        plan = {
            min: 500,
            max: 5000,
            step: 100,
        }
    } else if (process.env.REACT_APP_COUNTRY == "NZ") {
        plan = {
            min: 500,
            max: 5000,
            step: 100,
        }
    } else if (process.env.REACT_APP_COUNTRY == "IN") {
        plan = {
            min: 500,
            max: 5000,
            step: 100,
        }
    } else if (process.env.REACT_APP_COUNTRY == "NET") {
        plan = {
            min: 500,
            max: 5000,
            step: 100,
        }
    } else {
        plan = {
            min: 500,
            max: 5000,
            step: 100,
        }
    }
    return plan;

}

const getCurrencySymbol = () => {
    let symbol;
    if (process.env.REACT_APP_COUNTRY == "AU") {
        symbol = "dollar"
    } else if (process.env.REACT_APP_COUNTRY == "NZ") {
        symbol = "dollar"
    } else if (process.env.REACT_APP_COUNTRY == "IN") {
        symbol = "inr"
    } if (process.env.REACT_APP_COUNTRY == "NET") {
        symbol = "dollar"
    } else {
        symbol = "dollar"
    }
    return symbol;
}
export const locationsJSON = locations;
export const frontendUrl = FrontendUrl;
export const ServerUrl = serverUrl;
export const SocketIoUrl = socketIoUrl;
export const SocketIoAutoQuoteUrl = socketIoAutoQuoteUrl;
export const countryName = Country;
export const countryDialCode = Country_dial_code;
export const countryCode = Country_code;
export const businessNumberPlaceholder = businessNumber_Placeholder;
export const businessNumberLookupMessage = businessNumber_LookupMessage;
export const businessNumberLookupLink = businessNumber_LookupLink;
export { getPlans, getCurrencySymbol, getMorePlans, getMorePlansSettings };




