import {
    FETCH_BUSINESS_USER,
    FETCH_PROFESSION, FETCH_REVIEWS_BY_PROFESSION, FETCH_REVIEWS_BY_USER_ID,
    GET_ALL_PROFESSIONS,
    GET_SELECTED_BUSINESSES
} from "../constants/ActionTypes";

const initialState = {
    professions: [],
    selectedBusinesses: [],
    selectedUser: {},
    profession: [],
    professionReviews: [],
    userReviews: []
};

export const BusinessDirectoryReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_ALL_PROFESSIONS:
            return {...state, professions: payload}
        case GET_SELECTED_BUSINESSES:
            //return {...state, selectedBusinesses: state.selectedBusinesses.concat(payload)}
            return {...state, selectedBusinesses: payload}
        case FETCH_BUSINESS_USER:
            return {...state, selectedUser: payload}
        case FETCH_PROFESSION:
            return {...state, profession: payload}
        case FETCH_REVIEWS_BY_PROFESSION:
            return {...state, professionReviews: payload}
        case FETCH_REVIEWS_BY_USER_ID:
            return {...state, userReviews: payload}
        default:
            return state
    }
}
